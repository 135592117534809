import { theme } from "@config/theme"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { Link } from "gatsby"
import { Button } from "shards-react"
import styled from "styled-components"

interface IProps {
  title: string
  link: string

  // Optional
  children?: React.ReactNode
  classes?: any
  size?: string

  // with ICON
  withIcon?: boolean
  icon?: IconProp
}

const Buttons = styled(Button)`
  border-radius: 5px !important;
  background-color: ${theme.colors.background} !important;
  border-color: ${theme.colors.lightBackground};
  border-width: 3px;
  color: white;
  &:hover {
    border-color: ${theme.colors.darkBackground};
    background-color: ${theme.colors.lightBackground} !important;
    color: white;
  }
  &:active {
    border-color: ${theme.colors.darkBackground};
    color: white;
  }
  a {
    color: white;
    text-decoration: none;
  }
`

export default ({
  title,
  link,
  withIcon,
  icon,
  classes,
  size,
  children,
}: IProps) => {
  return (
    <Buttons
      size={size}
      className={classes}
      theme={theme.colors.darkBackground}
    >
      {withIcon && (
        <span>
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
      {children}
      <Link to={link}>{title}</Link>
    </Buttons>
  )
}
